/// Backgrounds //////////
.g-bg-primary{
    background-color: var(--color-primary);
}

.g-bg-white{
    background-color: var(--color-white) !important;
}






.g-color-dark{
    color: var(--color-dark) !important;
}

.g-color-white{
    color: var(--color-white);
}

.bg-primary{
    background-color: var(--color-primary) !important;
}

.g-color-success{
    color: var(--success-color);
}





// Text Colors ///////////////

.g-color-primary{
    color: var(--color-primary) !important;
}

.btn-primary{
    background: var(--color-primary);
    --bs-btn-border-color: var(--color-primary) !important;
    --bs-btn-hover-bg: var(--color-primary) !important;
    --bs-btn-hover-border-color: var(--color-primary) !important;

    &:active{
        background: var(--color-primary) !important;
        border: inherit;
    }
}